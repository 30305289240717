@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #1e293b;
  color: white;
  overflow-x: hidden;
}

html {
  font-size: 14px;
}

/*Global Scroll Bar*/
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  scrollbar-width: auto;
  scrollbar-color: #1e293b #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: #1e293b;
  border-radius: 8px;
  border: 2px solid #ffffff;
}

::-webkit-scrollbar-track {
  background-color: #f1f5f9;
  border-radius: 4px;
}

/* Spinner.css */
.spinner-border {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #797a7b;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  display: inline-block;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
